
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined, UserAddOutlined } from '@ant-design/icons-vue'

import ListClient from '@/components/agency/client/ListClient.vue'
import ClientService from '../../../services/profile'

interface ClientStatistc {
  no_of_clients: number;
  active_clients: number;
  inactive_clients: number;
  expired_clients: number;
}

export default defineComponent({
  components: {
    HomeOutlined,
    UserAddOutlined,
    ListClient
  },
  setup () {
    const statistics = ref<ClientStatistc>()
    const getClientStatistics = async () => {
      const responce: any = await ClientService.getStatistics()
      statistics.value = responce.data
    }
    const refreshStatistics = async () => {
      getClientStatistics()
    }
    onMounted(() => {
      getClientStatistics()
    })
    return {
      statistics,
      getClientStatistics,
      refreshStatistics
    }
  }
})
