<template>
  <div class="clients">
    <div class="body">
      <a-row class="filter">
        <div v-if="!showResendButton"></div>
        <a-button type="primary" v-if="showResendButton" @click="resendCredentials()" :loading="loading">
          Resend Login
          <template #icon><SendOutlined /></template>
        </a-button>
        <a-input-search v-model:value="searchString" placeholder="Search here ..." @search="onSearch" />
      </a-row>
      <a-table :row-selection="rowSelection" :columns="columns" :data-source="clients" rowKey="_id"
        :pagination="{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40', '50']
        }"
      >
        <template #clientName="{ record }">
          <router-link :to="{ name: 'View Client', params: { id: record._id }}">{{record.client_name}}</router-link>
        </template>
        <template #fNameSection="{ record }">
          <router-link :to="{ name: 'View Client', params: { id: record._id }}">{{record.first_name}}</router-link>
        </template>
        <template #is_active="{ text: is_active }">
          <span>
            <a-tag :color="is_active ? 'green' : 'red'"> {{ is_active ? 'Active' : 'Pending' }} </a-tag>
          </span>
        </template>
        <template #action="{ record, index }">
          <a-tooltip>
            <template #title>Activate Client</template>
            <CheckCircleOutlined @click="changeClientStatus(record, true, index)" v-if="!record.is_active" :style="{ fontSize: '15px', color: '#52c41a' }" />
          </a-tooltip>
          <a-tooltip>
            <template #title>De-Activate Client</template>
            <CloseCircleOutlined @click="changeClientStatus(record, false, index)" v-if="record.is_active" :style="{ fontSize: '15px', color: '#ff7875' }" />
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip>
            <template #title>Edit Client</template>
            <EditOutlined @click="editClient(record)" :style="{ fontSize: '15px' }" />
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip>
            <template #title>Delete Client</template>
            <DeleteOutlined @click="showDeleteConfirm(record, index)" :style="{ fontSize: '15px' }" />
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip>
            <template #title>Login As Client</template>
            <LoginOutlined @click="showLoginConfirm(record)" :style="{ fontSize: '15px' }" />
          </a-tooltip>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script lang="ts">
import { createVNode, defineComponent, onMounted, ref, computed, unref } from 'vue'
import { notification, Modal } from 'ant-design-vue'
import { ColumnProps } from 'ant-design-vue/es/table/interface'
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined, CheckCircleOutlined, CloseCircleOutlined, SendOutlined, LoginOutlined } from '@ant-design/icons-vue'

import profileService from '../../../services/profile'

import IClient from '../../../interface/profile'

import router from '../../../router'
import searchService from '../../../services/filter'

type Key = ColumnProps['key'];
export default defineComponent({
  components: {
    DeleteOutlined,
    EditOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    SendOutlined,
    LoginOutlined
  },
  setup (probs, { emit }) {
    const loading = ref<boolean>(false)
    const selectedRowKeys = ref<Key[]>([])
    const showResendButton = ref<boolean>(false)
    const searchString = ref<string>('')
    const clientData = ref<Array<IClient>>([])
    const clients = ref<Array<IClient>>([])
    const columns = [
      {
        title: 'Client Name',
        onFilter: (value, record) => record.first_name.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.length - b.first_name.length,
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'clientName' },
        width: '15%'
      },
      {
        title: 'First Name',
        onFilter: (value, record) => record.first_name.indexOf(value) === 0,
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
        sortDirections: ['descend', 'ascend'],
        slots: { customRender: 'fNameSection' },
        width: '15%'
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        onFilter: (value, record) => record.last_name.indexOf(value) === 0,
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
        sortDirections: ['descend', 'ascend'],
        width: '15%'
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '20%'
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        onFilter: (value, record) => record.phone.indexOf(value) === 0,
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ['descend', 'ascend'],
        width: '15%'
      },
      {
        title: 'Status',
        dataIndex: 'is_active',
        slots: { customRender: 'is_active' },
        width: '5%'
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' },
        width: '15%'
      }
    ]
    const onSearch = (searchValue: string) => {
      console.log('use value', searchValue)
      clients.value = searchService.search(clientData.value, searchValue)
    }
    const getClients = async () => {
      const responce = await profileService.getClients('client')
      clients.value = responce.data
      clientData.value = responce.data
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const deleteClient = async (profile, index) => {
      try {
        await profileService.deleteProfile(profile._id) // Delete Profile
        await profileService.deleteUser(profile.email) // Delete User
        clients.value.splice(index, 1)
        notify('Success', 'Deleted Successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const showLoginConfirm = async (profile) => {
      Modal.confirm({
        title: 'Are you sure to login as client?',
        icon: createVNode(LoginOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Are you sure want to login as client?. This will login the user in new window.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          localStorage.setItem('loginAsProfile', JSON.stringify(profile))
          localStorage.setItem('loginAsSubscription', JSON.stringify(profile.subscription ? profile.subscription : {}))
          window.open('client/dashboard?loginAs=client', '_blank')
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const showDeleteConfirm = async (profile, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          deleteClient(profile, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const changeClientStatus = async (client, status, index) => {
      try {
        client.is_active = status
        client.profile_type = client.profile_type._id
        await profileService.activateDeactivate(client._id, client)
        emit('refreshStatistics')
        notify('Success', (status ? 'Client activated' : 'Client de-activated'), 'success')
        clients.value[index].is_active = status
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const editClient = async (client) => {
      router.push(`/clients/edit/${client._id}`)
    }
    const onSelectChange = (changableRowKeys: Key[]) => {
      console.log('selectedRowKeys changed: ', changableRowKeys)
      selectedRowKeys.value = changableRowKeys
      if (changableRowKeys.length > 0) {
        showResendButton.value = true
      } else {
        showResendButton.value = false
      }
    }
    const resendCredentials = async () => {
      try {
        loading.value = true
        await profileService.resendCredentials({ profileIds: selectedRowKeys.value })
        loading.value = false
        showResendButton.value = false
        selectedRowKeys.value = []
        await notify('Success', 'Login credentials send successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const rowSelection = computed(() => {
      return {
        selectedRowKeys: unref(selectedRowKeys),
        onChange: onSelectChange,
        hideDefaultSelections: true,
        selections: [
          {
            key: 'all-data',
            text: 'Select All Data',
            onSelect: (changableRowKeys: Key[]) => {
              console.log('changableRowKeys', changableRowKeys)
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'odd',
            text: 'Select Odd Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return false
                }
                return true
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          },
          {
            key: 'even',
            text: 'Select Even Row',
            onSelect: (changableRowKeys: Key[]) => {
              let newSelectedRowKeys = []
              newSelectedRowKeys = changableRowKeys.filter((key, index) => {
                if (index % 2 !== 0) {
                  return true
                }
                return false
              })
              selectedRowKeys.value = newSelectedRowKeys
            }
          }
        ]
      }
    })
    onMounted(() => {
      getClients()
    })
    return {
      clients,
      columns,
      showDeleteConfirm,
      editClient,
      changeClientStatus,
      rowSelection,
      loading,
      resendCredentials,
      onSearch,
      searchString,
      showResendButton,
      showLoginConfirm
    }
  }
})
</script>
<style lang="scss">
.clients {
  padding: 10px;
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 5px;
    width: 100%;
    .filter {
      display: flex;
      justify-content: space-between;
      margin: 15px 0 15px 0;
      .noofentries {
        display: flex;
        align-items: center;
        .ant-select {
          margin: 0 5px;
        }
      }
      .ant-input-affix-wrapper {
        width: 200px;
      }
      .ant-select,
      .ant-btn {
        float: left;
      }
      .ant-btn {
        background: #7EAF1A;
        border-color: #7EAF1A;
        border-radius: 20px;
        font-family: "TT Norms Pro Medium";
      }
    }
  }
}
</style>
